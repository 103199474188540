@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css";
@import "../node_modules/@syncfusion/ej2-notifications/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-pdfviewer/styles/material.css";

body {
  margin: 0;
  font-family: "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
  border-radius: 0px !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background: rgb(179, 179, 179);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(150, 150, 150);
}

.phone-input-dropdown > .chakra-select__wrapper {
  height: 100%;
}

.top-nav-avatar > .chakra-avatar__initials {
  width: 100%;
}

/* Form MultiSelect Checkboxes Styles */
.form-multi-select-checkboxes.css-b62m3t-container {
  width: 190px;
}

.form-multi-select-checkboxes__control {
  height: 40px !important;
  border-radius: 0.125rem !important;
}

.form-multi-select-checkboxes__value-container {
  height: 34px !important;
}
.custom-checkbox:checked {
  border-color: red;
  background-color: red;
}
.form-multi-select-checkboxes__indicator.form-multi-select-checkboxes__dropdown-indicator,
.form-multi-select-checkboxes__indicator-separator.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.form-multi-select-checkboxes__indicator.form-multi-select-checkboxes__clear-indicator.css-1xc3v61-indicatorContainer {
  background-color: #eaeaea;
  height: 40px;
  display: flex;
  align-items: center;
}

.form-multi-select-checkboxes__menu {
  width: fit-content !important;
  min-width: 100% !important;
}
/* Custom Popover */

.custom-popover > .chakra-popover__popper {
  z-index: 9999999;
}

/* Animations */
@keyframes rotation {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
